import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore, PreloadedState } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import rootReducer from "../reducers";
import { corePlusIncentivesApi } from "../services/CorePlusIncentives/corePlusIncentivesApi";
import { accAPI } from "../services/index";
import { businessSelectorApi } from "../services/BusinessSelector/businessSelectorAPI";
import { shellConfigApi } from "../services/ShellConfig/getShellConfigAPI";
import { monthlyPerformanceDashboardAPI } from "../services/MonthlyPerformanceDashboard/monthlyPerformanceDashboardAPI";
import { savedKpiPreferencesAPI } from "../services/KPIPreferences/savedKpiPreferencesAPI";
import { hatSummaryAPI } from "../services/HATSummary/hatSummaryAPI";
import { frontlineGroupGrowthAPI } from "../services/GroupGrowth/FrontlineGroupGrowth/frontlineGroupGrowthAPI";
import { ssiAPI } from "../services/StrongStart/ssiAPI";
import { lsiAPI } from "../services/LeadershipSeminar/leadershipSeminarAPI";
import { personalGroupGrowthAPI } from "../services/GroupGrowth/PersonalGroupGrowth/personalGroupGrowthAPI";
import { pggGcrAPI } from "../services/GroupGrowth/PersonalGroupGrowthGCR/personalGroupGrowthGCRAPI";
import { performancePlusEliteAPI } from "../services/PerformancePlusElite/performancePlusEliteAPI";
import { annualPerformanceDashboardAPI } from "../services/AnnualPerformanceDashboard/annualPerformanceDashboardAPI";
import { monthlyPGGAPI } from "../services/GroupGrowth/MonthlyPersonalGroupGrowth/monthlyPGGAPI";
import { qcgAPI } from "../services/GroupGrowth/QcGrowth/qcGrowthAPI";

// https://stackoverflow.com/questions/66315413/type-definitions-for-redux-toolkit-store-with-preloadedstate
export const setupStore = (preloadedState?: PreloadedState<any>) =>
  configureStore({
    reducer: rootReducer,
    ...preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(corePlusIncentivesApi.middleware)
        .concat(accAPI.middleware)
        .concat(hatSummaryAPI.middleware)
        .concat(businessSelectorApi.middleware)
        .concat(shellConfigApi.middleware)
        .concat(monthlyPerformanceDashboardAPI.middleware)
        .concat(savedKpiPreferencesAPI.middleware)
        .concat(annualPerformanceDashboardAPI.middleware)
        .concat(frontlineGroupGrowthAPI.middleware)
        .concat(personalGroupGrowthAPI.middleware)
        .concat(pggGcrAPI.middleware)
        .concat(ssiAPI.middleware)
        .concat(lsiAPI.middleware)
        .concat(qcgAPI.middleware)
        .concat(monthlyPGGAPI.middleware)
        .concat(performancePlusEliteAPI.middleware),
  });

export type Store = ReturnType<typeof setupStore>;
export type RootState = ReturnType<Store["getState"]>;
export type AppDispatch = Store["dispatch"];

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// setup store
export const store = setupStore();

// persist redux store so that the state gets rehydrated on page refresh
export const persistor = persistStore(store);
