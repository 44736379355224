import { accAPI } from "..";
import {
  CurrentViewExportReqBody,
  DeleteExportResponse,
  DownloadExportParams,
  DownloadExportResponse,
  ExportIdParam,
  ExportListResponse,
  ExportModuleIdParam,
  ExportNotifyResponse,
  ExportResponse,
  FullMapExportReqBody,
} from "./exportV2API.types";

export const TAG_EXPORT_LIST = "ExportListV2";
export const TAG_EXPORT_NOTIFY = "ExportNotifyV2";

const urlBase = "apiBaseUrl";
const basedPath = "/export-v2/api";

export const exportV2API = accAPI
  .enhanceEndpoints({
    addTagTypes: [TAG_EXPORT_LIST, TAG_EXPORT_NOTIFY],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fullmapExportV2: build.mutation<ExportResponse, FullMapExportReqBody>({
        query: (body) => ({
          url: `${basedPath}/fullmap`,
          method: "POST",
          body,
        }),
        invalidatesTags: (_result, _error, arg) => {
          // invalidate the export list cache to trigger the getExportListV2 query refetch automatically
          const { moduleId } = arg;
          return [{ type: TAG_EXPORT_LIST, id: moduleId }];
        },
        extraOptions: {
          urlBase,
        },
      }),
      currentViewExportV2: build.mutation<ExportResponse, CurrentViewExportReqBody>({
        query: (body) => ({
          url: `${basedPath}/currentview`,
          method: "POST",
          body,
        }),
        invalidatesTags: (_result, _error, arg) => {
          // invalidate the export list cache to trigger the getExportListV2 query refetch automatically
          const { moduleId } = arg;
          return [{ type: TAG_EXPORT_LIST, id: moduleId }];
        },
        extraOptions: {
          urlBase,
        },
      }),
      getExportListV2: build.query<ExportListResponse, ExportModuleIdParam>({
        query: (params) => {
          const { moduleId } = params;
          const queryParams = new URLSearchParams({
            moduleId,
          });
          return `${basedPath}/?${queryParams}`;
        },
        providesTags: (_result, _error, arg) => {
          // set up the tag for other query/mutation to invalidate cache
          const { moduleId } = arg;
          return [{ type: TAG_EXPORT_LIST, id: moduleId }];
        },
        // keepUnusedDataFor: 0,
        extraOptions: {
          urlBase,
        },
      }),
      deleteExportV2: build.mutation<DeleteExportResponse, ExportIdParam>({
        query: ({ exportId }) => ({
          url: `${basedPath}/${exportId}`,
          method: "DELETE",
        }),
        extraOptions: {
          urlBase,
        },
      }),
      getExportDownloadV2: build.query<DownloadExportResponse, DownloadExportParams>({
        query: (params) => {
          const { ExportID, FileFormat, DownloadFileName } = params;
          const queryParams = new URLSearchParams({
            ExportID,
            FileFormat,
            DownloadFileName,
          });
          return `${basedPath}/download?${queryParams}`;
        },
        extraOptions: {
          urlBase,
        },
      }),
      getExportNotificationV2: build.query<ExportNotifyResponse, ExportModuleIdParam>({
        query: ({ moduleId }) => {
          const queryParams = new URLSearchParams({
            ModuleID: moduleId,
          });
          return `${basedPath}/notify?${queryParams}`;
        },
        extraOptions: { urlBase },
      }),
    }),
  });

export const {
  useFullmapExportV2Mutation,
  useCurrentViewExportV2Mutation,
  useGetExportListV2Query,
  useDeleteExportV2Mutation,
  useLazyGetExportDownloadV2Query,
  useGetExportNotificationV2Query,
  util,
} = exportV2API;
