import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { LeafModal } from "../../../common/leaf/LeafModal/LeafModal";
import ServerSideExportMenu, {
  ExportButtonPayload,
} from "../ServerSideExportCommon/ServerSideExportMenu/ServerSideExportMenu";
import ServerSideExportDownload from "../ServerSideExportCommon/ServerSideExportDownload/ServerSideExportDownload";
import { storedLocale } from "../../impersonation/util";
import { useAppSelector } from "../../../store";
import { CURRENT_VIEW, FULL_MAP, FULL_MAP_SELECTED_KPIS } from "../../../common/constants/export-constants";
import {
  useCurrentViewExportV2Mutation,
  useFullmapExportV2Mutation,
  useGetExportListV2Query,
} from "../../../services/ExportV2/exportV2API";
import { LOS_GRID_CONFIG } from "../../Lit/acc-wc-views/src/acc-wc-view-los-indented/enum";
import {
  CurrentViewExportReqBody,
  ExportDetails,
  FullMapExportReqBody,
} from "../../../services/ExportV2/exportV2API.types";
import {
  dispatchNotifyInProgressEvent,
  dispatchOpenExportV2ModalEvent,
} from "../ServerSideExportCommon/ServerSideExportUtil/ServerSideExportEvents";
import "./LOSExportModal.scss";
import defaultContent from "../../../content/serverSideExport";
import {
  exportAnotherMenuTypeTrack,
  exportDeleteTypeTrack,
  exportDownloadTypeTrack,
  exportViewMenuTypeTrack,
} from "../../../common/tealium/Export/ServerSideExportTrack";

type Props = {
  open: boolean;
  onCloseCallbackFn: Function;
};

export const LOSExportModal = ({ open, onCloseCallbackFn }: Props) => {
  const { t } = useTranslation(["serverSideExport"]);

  const moduleId = "los";
  const locale = storedLocale();

  const boot = useAppSelector(({ boot }) => boot);
  const { user } = boot;
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { profile } = useAppSelector((store) => store?.boot?.configuration);

  const [fullmapExport] = useFullmapExportV2Mutation();
  const [currentViewExport] = useCurrentViewExportV2Mutation();

  const { isFetching, data: exportListData, refetch } = useGetExportListV2Query({ moduleId });

  const [showServerSideExportMenu, setShowServerSideExportMenu] = useState(true);

  // when open modal, decide which screen to display
  useEffect(() => {
    if (open) {
      const showExportMenu = exportListData?.Exports?.length === 0;
      setShowServerSideExportMenu(showExportMenu);
    }
  }, [open, exportListData]);

  // refetch the export list when change business country
  useEffect(() => {
    refetch();
  }, [user, refetch]);

  const options = [
    {
      id: FULL_MAP,
      text: `${t("exportTypeModal.menu.los.all", defaultContent.exportTypeModal.menu.los.all)}`,
    },
    {
      id: FULL_MAP_SELECTED_KPIS,
      text: `${t("exportTypeModal.menu.los.selectedKPIs", defaultContent.exportTypeModal.menu.los.selectedKPIs)}`,
    },
    {
      id: CURRENT_VIEW,
      text: `${t("exportTypeModal.menu.los.currentView", defaultContent.exportTypeModal.menu.los.currentView)}`,
    },
  ];

  const showViewExportsButton = (exportListData?.Exports ?? []).length > 0;

  const onExportAnotherClick = () => {
    exportAnotherMenuTypeTrack(moduleId);
    setShowServerSideExportMenu(true);
  };

  const getKPIsContent = (kpis: string[]) => {
    const exportFields: Record<string, string> = t("exportFields", {
      returnObjects: true,
      defaultValue: defaultContent.exportFields,
    });

    return kpis.map((id) => ({
      id,
      title: exportFields[id] || "not available",
    }));
  };

  const getKPIs = (payload: ExportButtonPayload) => {
    const { selectedId, fullmap, currentView } = payload;

    switch (selectedId) {
      case FULL_MAP:
        return getKPIsContent(fullmap.avaliableKPIs);
      case FULL_MAP_SELECTED_KPIS:
        return getKPIsContent(fullmap.selectedKPIs);
      default:
        return getKPIsContent(currentView.selectedKPIs);
    }
  };

  const getExportPayload = (payload: ExportButtonPayload) => {
    const { selectedId, fullmap, currentView, reportAffAbo } = payload;

    const { address, addressLinesDivider } = profile?.format || {};

    const uiGrid = boot?.configuration?.rwlos?.uiGrid || "";
    const strictInMarket = uiGrid.includes(LOS_GRID_CONFIG.DATA_SHOW_INTERNATIONAL_ABOS) === false;
    const isoCountryCode = boot?.user?.isoCountryCode || "";

    const textOverride = t("textOverride", { returnObjects: true, defaultValue: defaultContent.textOverride });
    const fields = getKPIs(payload);

    const exportBodyBased = {
      moduleId,
      reportAffAbo,
      period: selectedPeriod,
      locale,
      textOverride,
      fields,
      strictInMarket,
      countryCode: isoCountryCode,
      format: {
        address: {
          pattern: address,
          addressLineDivider: addressLinesDivider,
        },
      },
    };

    switch (selectedId) {
      case FULL_MAP:
        return {
          ...exportBodyBased,
          marketType: fullmap.marketType,
          filteredByKPIS: false,
        };
      case FULL_MAP_SELECTED_KPIS:
        return {
          ...exportBodyBased,
          marketType: fullmap.marketType,
          filteredByKPIS: true,
        };
      default:
        return {
          ...exportBodyBased,
          affAbos: currentView.currentViewList,
          filteredByKPIS: false,
        };
    }
  };

  const triggerExportRequest = async (payload: ExportButtonPayload) => {
    const { selectedId } = payload;
    const exportPayload = getExportPayload(payload);

    if (selectedId === CURRENT_VIEW) {
      currentViewExport(exportPayload as CurrentViewExportReqBody);
    } else {
      fullmapExport(exportPayload as FullMapExportReqBody);
    }
    // dismiss modal
    dispatchOpenExportV2ModalEvent(moduleId, false);
    // send notification
    dispatchNotifyInProgressEvent();
    setShowServerSideExportMenu(false);
  };

  const viewAllExportedDataButtonOnClick = () => {
    exportViewMenuTypeTrack(moduleId);
    setShowServerSideExportMenu(false);
  };

  const downloadButtonOnClick = (exportDetails: ExportDetails, fileFormat: string, moduleId: string) => {
    const { ExportType, FilteredByKPIS } = exportDetails;
    exportDownloadTypeTrack(ExportType, fileFormat, FilteredByKPIS, moduleId);
  };
  const deleteButtonOnClick = (exportDetails: ExportDetails, moduleId: string) => {
    const { ExportType, ExportStatus, FilteredByKPIS } = exportDetails;
    exportDeleteTypeTrack(ExportType, ExportStatus, FilteredByKPIS, moduleId);
  };

  return (
    <div className="los-export-modal">
      <LeafModal open={open} onCloseCallbackFn={onCloseCallbackFn} id={"los-export-modal"}>
        <ServerSideExportMenu
          title={`${t("exportTypeModal.header", defaultContent.exportTypeModal.header)}`}
          subTitle={`${t("exportTypeModal.subheader", defaultContent.exportTypeModal.subheader)}`}
          options={options}
          exportButton={{
            text: `${t("exportTypeModal.exportButton", defaultContent.exportTypeModal.exportButton)}`,
            onClick: triggerExportRequest,
          }}
          show={showServerSideExportMenu}
          showExportButton={true}
          showViewExportsButton={showViewExportsButton}
          viewExportsButton={{
            text: `${t("exportTypeModal.viewExportButton", defaultContent.exportTypeModal.viewExportButton)}`,
            onClick: viewAllExportedDataButtonOnClick,
          }}
          moduleId={moduleId}
          isFetching={isFetching}
        />
        <ServerSideExportDownload
          title={`${t("availableExportModal.header", defaultContent.availableExportModal.header)}`}
          subTitle={`${t("availableExportModal.subheader", defaultContent.availableExportModal.subheader)}`}
          exportTypeContent={{
            fullMap: `${t(
              "exportTypeModal.menu.los.selectedKPIs",
              defaultContent.exportTypeModal.menu.los.selectedKPIs,
            )}`,
            fullMapAllKPIs: `${t("exportTypeModal.menu.los.all", defaultContent.exportTypeModal.menu.los.all)}`,
            currentView: `${t(
              "exportTypeModal.menu.los.currentView",
              defaultContent.exportTypeModal.menu.los.currentView,
            )}`,
          }}
          exports={exportListData?.Exports}
          downloadButtonOnClick={downloadButtonOnClick}
          deleteButtonOnClick={deleteButtonOnClick}
          show={showServerSideExportMenu === false}
          showExportAnotherButton={true}
          exportAnotherButton={{
            text: `${t(
              "availableExportModal.exportAnotherButton",
              defaultContent.availableExportModal.exportAnotherButton,
            )}`,
            onClick: onExportAnotherClick,
          }}
          moduleId={moduleId}
          isFetching={isFetching}
        />
      </LeafModal>
    </div>
  );
};
