import React from "react";
import { useTranslation } from "react-i18next";
import LeafToastMessage from "../../../common/leaf/LeafToastMessage/LeafToastMessage";
import { IconCheckCircle } from "../../../common/icons/IconCheckCircle/IconCheckCircle";
import LeafLinkButton from "../../../common/leaf/LeafLinkButton/LeafLinkButton";
import { useAppDispatch } from "../../../store";
import { showExport } from "../../../reducers/customer/customerExport";
import { trackExportNotification } from "../../../common/tealium/Customers/CustomerExport/CustomerExportTrack";
import defaultContent from "../../../content/serverSideExport";
import "./CustomerExportNotifyContainer.scss";

type Props = {
  showToast: boolean;
};

const CustomerExportNotifyContainer = ({ showToast }: Props) => {
  const { t } = useTranslation(["serverSideExport"]);

  const dispatch = useAppDispatch();

  const downloadsLinkOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    trackExportNotification();
    dispatch(showExport());
  };

  return (
    <>
      <LeafToastMessage
        htmlContent={
          <div className={`export-toast`}>
            <div className={`export-toast__icon`}>
              <IconCheckCircle size={`sm`} color={`green`} />
            </div>
            <div className={`export-toast__content`}>
              <div className={"export-toast__text"}>
                <div className={`export-toast__title`}>{`${t(
                  "customer.exportNotifyContainer.toastTitle",
                  defaultContent.customer.exportNotifyContainer.toastTitle,
                )}`}</div>
                <div className={`export-toast__subtitle`}>
                  {`${t(
                    "customer.exportNotifyContainer.toastSubTitle",
                    defaultContent.customer.exportNotifyContainer.toastSubTitle,
                  )}`}
                </div>
              </div>
              <div className={`export-toast__download-link`}>
                <LeafLinkButton
                  onClick={downloadsLinkOnClick}
                  theme={"green"}
                  label={`${t(
                    "customer.exportNotifyContainer.toastButtonText",
                    defaultContent.customer.exportNotifyContainer.toastButtonText,
                  )}`}
                  fontWeight={"bold"}
                >
                  {`${t(
                    "customer.exportNotifyContainer.toastButtonText",
                    defaultContent.customer.exportNotifyContainer.toastButtonText,
                  )}`}
                </LeafLinkButton>
              </div>
            </div>
          </div>
        }
        showToast={showToast}
        position={"top-center"}
        autoClose={10000}
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        draggable={false}
      />
    </>
  );
};

export default CustomerExportNotifyContainer;
