export const fileNameBuilder = (fileFormat: string, exportModuleName: string) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");
  const fileName = `${day}${month}${year}_${exportModuleName}`;
  return fileFormat ? `${fileName}.${fileFormat}` : fileName;
};

export const losFileNameBuilder = (period: string) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");

  return `${day}${month}${year}_${period}`;
};
